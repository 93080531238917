import ApiService from "@/services/ApiService";
import { OrderDetails } from "@/models/OrderDetails";

const apiService = ApiService;
const paymentMethodService = {
    getAll: async () => {
        return apiService.get<OrderDetails["paymentMethod"][]>(
            "/api/v1/payment/methods",
        );
    },
};

export default paymentMethodService;
