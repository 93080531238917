<script setup lang="ts">
    import { useDisplay } from "vuetify";
    import { onMounted, ref } from "vue";
    import useVuelidate from "@vuelidate/core";
    import { getErrors, required } from "@/composables/dutchVuelidate";
    import {
        email as emailValidator,
        telephone as telephoneValidator,
    } from "@/composables/dutchVuelidate";
    import authenticationService from "@/services/AuthenticationService";
    import { useRouter } from "vue-router";
    import axios from "axios";
    import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
    import PasswordField from "@/components/authentication/PasswordField.vue";

    const { smAndDown } = useDisplay();

    const email = ref("");
    const firstname = ref("");
    const infix = ref("");
    const lastname = ref("");
    const telephone = ref("");
    const password = ref("");

    const rules = {
        email: {
            required,
            email: emailValidator,
        },
        firstname: {
            required,
        },
        lastname: {
            required,
        },
        telephone: {
            required,
            telephone: telephoneValidator,
        },
    };

    const v$ = useVuelidate(rules, {
        email,
        firstname,
        infix,
        lastname,
        telephone,
    });

    const error = ref();
    const loading = ref(false);

    const router = useRouter();

    const hcaptchaSitekey = ref();
    const hcaptchaResult = ref("");
    const captchaVerified = ref(false);
    const hcaptcha = ref<VueHcaptcha>();

    onMounted(async () => {
        hcaptchaSitekey.value = await authenticationService.hcaptchaSitekey();
    });

    const onHCaptchaVerify = (tokenStr: string) => {
        captchaVerified.value = true;
        hcaptchaResult.value = tokenStr;
    };

    const onHCaptchaClose = () => {
        loading.value = false;
    };

    const submit = async () => {
        loading.value = true;
        v$.value.$touch();
        if (v$.value.$invalid) {
            loading.value = false;
            return;
        }

        if (!captchaVerified.value) {
            await hcaptcha.value?.executeAsync();
        }

        if (!captchaVerified.value) {
            return;
        }

        // Send an API call to register the user.
        try {
            await authenticationService.register(
                email.value,
                password.value,
                firstname.value,
                infix.value,
                lastname.value,
                telephone.value,
                hcaptchaResult.value,
            );
            // Redirect the user to the login page.
            await router.push({ name: "login", query: { registered: "true" } });
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = e?.response;
                if (response?.status === 409) {
                    error.value =
                        "Er bestaat al een account met deze gegevens.";
                } else {
                    error.value =
                        "Er is iets misgegaan. Probeer het later opnieuw.";
                }
            }
        } finally {
            loading.value = false;
        }
    };
</script>

<template>
    <v-expand-transition>
        <span v-show="error">
            <v-alert
                theme="light"
                type="error"
                data-cy="alert"
                :max-width="smAndDown ? undefined : 500"
            >
                {{ error }}
            </v-alert>
            <br />
        </span>
    </v-expand-transition>
    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-carousel height="200px" :show-arrows="false" :hide-delimiters="true">
            <v-carousel-item
                cover
                src="https://sinterklaas-raalte.nl/wp-content/uploads/2022/10/4.png"
            />
        </v-carousel>
        <v-card-title>Registreren</v-card-title>
        <v-card-text>
            <v-form @keyup.enter="submit">
                <div class="text-subtitle-1 text-medium-emphasis">Email*</div>
                <v-text-field
                    v-model="email"
                    density="compact"
                    placeholder="E-mailadres"
                    variant="outlined"
                    type="email"
                    hide-details="auto"
                    data-cy="email"
                    :error-messages="getErrors(v$.email.$errors)"
                    @blur="v$.email.$touch"
                />
                <br />
                <div class="text-subtitle-1 text-medium-emphasis">
                    Voornaam*
                </div>
                <v-text-field
                    v-model="firstname"
                    density="compact"
                    placeholder="Voornaam"
                    hide-details="auto"
                    :error-messages="getErrors(v$.firstname.$errors)"
                    variant="outlined"
                    data-cy="firstName"
                    @blur="v$.firstname.$touch"
                />
                <br />
                <div class="text-subtitle-1 text-medium-emphasis">
                    Tussenvoegsel
                </div>
                <v-text-field
                    v-model="infix"
                    density="compact"
                    hide-details
                    placeholder="Tussenvoegsel"
                    data-cy="infix"
                    variant="outlined"
                />
                <br />
                <div class="text-subtitle-1 text-medium-emphasis">
                    Achternaam*
                </div>
                <v-text-field
                    v-model="lastname"
                    density="compact"
                    hide-details="auto"
                    :error-messages="getErrors(v$.lastname.$errors)"
                    placeholder="Achternaam"
                    variant="outlined"
                    data-cy="lastName"
                    @blur="v$.lastname.$touch"
                />
                <br />
                <div class="text-subtitle-1 text-medium-emphasis">
                    Telefoonnummer*
                </div>
                <v-text-field
                    v-model="telephone"
                    density="compact"
                    hide-details="auto"
                    :error-messages="getErrors(v$.telephone.$errors)"
                    placeholder="Telefoonnummer"
                    variant="outlined"
                    type="tel"
                    data-cy="telephone"
                    @blur="v$.telephone.$touch"
                />
                <br />
                <password-field v-model="password" require-confirmation />
                <br />
                <vue-hcaptcha
                    v-if="hcaptchaSitekey"
                    ref="hcaptcha"
                    :sitekey="hcaptchaSitekey"
                    language="nl-nl"
                    @verify="onHCaptchaVerify"
                    @closed="onHCaptchaClose"
                />
                <br />
                <p class="mb-3 text-medium-emphasis">
                    Je gegeven worden verwerkt volgens
                    <router-link to="/privacy">ons privacy beleid</router-link>
                </p>
            </v-form>
            <v-row>
                <v-col>
                    <v-btn
                        variant="tonal"
                        color="primary"
                        @click="router.push({ name: 'login' })"
                    >
                        Ik heb al een account
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col class="flex-grow-0">
                    <v-btn
                        variant="flat"
                        :loading="loading"
                        color="primary"
                        @click="submit"
                        >Registreren
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
