import UserMasterPage from "@/views/userAdmin/UserMasterPage.vue";

export const userAdminRoutes = [
    {
        path: "/admin/users",
        component: UserMasterPage,
        name: "userAdmin",
        meta: {
            requiresAuth: true,
            onProd: true,
            roles: ["ROLE_ADMIN"],
        },
    },
];
