import { defineStore } from "pinia";
import { VAlert } from "vuetify/components";
import { unref } from "vue";

export const useNotificationStore = defineStore("notification", {
    state: () =>
        <State>{
            queue: [],
            currentNotification: undefined,
        },
    actions: {
        /* Make a notification pop up.
         * A default timeout of 8s is applied.
         * Specify the timeout parameter to override this default.
         * Set to false to remove the timeout.
         * Timeout parameter is in ms.
         *
         * If a notificationGroup is set, the notification is only
         * added to the displayed/added to the queue if none of that
         * group is in the queue or is the current notification.
         */
        addNotification(
            text: string,
            type?: VAlert["type"],
            icon?: VAlert["icon"] | false,
            timeout?: number | false,
            notificationGroup?: NotificationGroup,
        ) {
            const newNotification: Notification = {
                icon: icon ?? undefined,
                text: text,
                type: type ?? "error",
                timeout: timeout ?? 8000,
                notificationGroup: notificationGroup,
            };

            // Only push the notification if it does not already exist in the queue or is the current notification.
            if (
                notificationGroup !== undefined &&
                (this.currentNotification?.notificationGroup ==
                    notificationGroup ||
                    this.queue.some(
                        (notification) =>
                            notification.notificationGroup == notificationGroup,
                    ))
            ) {
                return;
            }

            if (this.queue.length === 0 && !this.currentNotification) {
                this.currentNotification = newNotification;
                this.activateTimeout();
            } else {
                this.queue.push(newNotification);
            }
        },
        popNotification() {
            // Remove the timeout of the old notification to prevent it from closing other notifications.
            clearTimeout(unref(this.currentNotification)?.timeoutID);
            this.currentNotification = this.queue.shift();
            this.activateTimeout();
        },
        activateTimeout() {
            if (
                this.currentNotification &&
                !this.currentNotification.timeoutID &&
                this.currentNotification.timeout
            ) {
                this.currentNotification.timeoutID = setTimeout(() => {
                    this.popNotification();
                }, this.currentNotification.timeout);
            }
        },
    },
});

interface State {
    queue: Array<Notification>;
    currentNotification?: Notification | undefined;
}

export interface Notification {
    type: VAlert["type"];
    text: string;
    icon: VAlert["icon"] | undefined | false;
    timeout: number | false;
    notificationGroup?: NotificationGroup;
    timeoutID?: ReturnType<typeof setTimeout>;
}

export enum NotificationGroup {
    ConnectionError,
    ApiInternalError,
}
