import axios from "axios";
import { useAuthStore } from "@/store/auth";
import { formatIntlTelephone } from "@/composables/formatter";
import { useInstanceStore } from "@/store/instance";

// The authentication service needs its own axios instance as it doesn't need the Authorization header
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
    const instanceStore = useInstanceStore();
    config.baseURL = instanceStore.apiUrl;
    return config;
});

export interface GlobyInstance {
    ApiUrl: string;
    CanonicalName: string;
}

const authService = {
    register: (
        email: string,
        password: string,
        firstName: string,
        infix: string,
        lastName: string,
        telephone: string,
        hcaptcha: string,
    ) => {
        const intlTelephone = formatIntlTelephone(telephone);

        return axiosInstance.post("/api/v1/auth/register", {
            email,
            password,
            firstName,
            infix,
            lastName,
            telephone: intlTelephone,
            hcaptcha,
        });
    },
    login: async (username: string, password: string) => {
        return await axiosInstance
            .post("/api/v1/auth/login", {
                username,
                password,
            })
            .then((response) => {
                // If the user has logged in successfully, store the information in the authStore
                const authStore = useAuthStore();

                const userData = response.data.userData;

                authStore.isAuthenticated = true;
                authStore.email = userData.email;
                authStore.roles = userData.roles;
                authStore.firstName = userData.firstName;
                authStore.infix = userData.infix;
                authStore.lastName = userData.lastName;

                authStore.accessToken = response.data.accessToken;
                authStore.refreshToken = response.data.refreshToken;

                return response;
            });
    },
    refresh: async () => {
        const authStore = useAuthStore();

        return axiosInstance
            .post("/api/v1/auth/refresh_token", {
                refreshToken: authStore.refreshToken?.token,
            })
            .then((response) => {
                // If refreshing the token was successfull, we get new informationa about the user.
                const userData = response.data.userData;

                authStore.email = userData.email;
                authStore.roles = userData.roles;
                authStore.firstName = userData.firstName;
                authStore.infix = userData.infix;
                authStore.lastName = userData.lastName;

                // Update access token
                authStore.accessToken = response.data.accessToken;
            });
    },
    hcaptchaSitekey: async () => {
        return axiosInstance.get("/api/v1/status/config").then((response) => {
            return response.data.captcha.hcaptcha.sitekey;
        });
    },
    requestReset: (email: string) => {
        return axiosInstance.post("/api/v1/user/password/reset", {
            email,
        });
    },
    resetPassword: (token: string, password: string) => {
        return axiosInstance.put("/api/v1/user/password", {
            token,
            password,
        });
    },
    getInstances: () => {
        return axiosInstance.get<GlobyInstance[]>(import.meta.env.NEXUS_URL);
    },
};

export default authService;
