<script setup lang="ts">
    import { computed } from "vue";
    import { VAlert } from "vuetify/components";
    import PickupSelector from "@/components/orderPlacement/fulfillment/PickupSelector.vue";
    import DeliveryForm from "@/components/orderPlacement/fulfillment/DeliveryForm.vue";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { formatPrice } from "@/composables/formatter";

    const delivery = computed({
        get: () => orderStore.order.fulfillment.deliver,
        set: (value: boolean) => {
            orderStore.setDelivery(value);
        },
    });

    const orderStore = useOrderCreationStore();
</script>

<template>
    <!-- Address or pickup -->
    <template v-if="orderStore.options.pickupPoints.length !== 0">
        <v-radio-group v-model="delivery" inline hide-details="auto">
            <v-radio :value="true" label="Bezorgen" data-cy="delivery-radio" />
            <v-radio :value="false" label="Ophalen" data-cy="pickup-radio" />
        </v-radio-group>
    </template>

    <template v-if="delivery">
        <delivery-form />
    </template>
    <template v-else>
        <pickup-selector />
    </template>

    <v-alert v-if="orderStore.locationFee !== 0">
        Er wordt voor deze locatie een extra toeslag gerekend van
        {{ formatPrice(orderStore.locationFee) }}
    </v-alert>

    <v-alert type="info" class="my-5">
        Let op: De prijzen worden bepaald op basis van de locatie van de klant.
    </v-alert>
</template>

<style scoped></style>
