<script setup lang="ts">
    import { displayItem } from "@/store/orderCreation/types";
    import { formatPrice } from "@/composables/formatter";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { computed } from "vue";
    import useVuelidate from "@vuelidate/core";
    import { maxValue, minValue } from "@/composables/dutchVuelidate";

    const props = defineProps<{
        product: displayItem;
        disabled: boolean;
    }>();

    const orderStore = useOrderCreationStore();

    const available = computed(() => {
        // Default to available
        return (
            orderStore.productIdToBind[props.product.id]?.timeslots.length ??
            1 > 0
        );
    });

    const fieldModel = computed({
        get: () => {
            return (
                orderStore.selectedProducts.find(
                    (product) => product.id == props.product.id,
                )?.amount ?? 0
            );
        },
        set: (val) => {
            updateOrder(val);
        },
    });

    const updateOrder = (val: number) => {
        const parsedVal = parseInt(val.toString());
        if (!isNaN(parsedVal)) {
            orderStore.updateProduct(props.product.id, parsedVal);
        }
    };

    const rules = computed(() => {
        return {
            fieldModel: {
                maxValue: maxValue(props.product.maxCapacity),
                minValue: minValue(0),
            },
        };
    });
    const v$ = useVuelidate(rules, { fieldModel });
</script>

<template>
    <tr>
        <td>{{ product.label }} {{ !available ? "(geen voorraad)" : "" }}</td>
        <td>
            {{ product.price ? formatPrice(product.price) : "" }}
        </td>
        <td>
            <v-text-field
                v-model="fieldModel"
                density="compact"
                variant="outlined"
                :data-cy="'product-' + product.id"
                :hide-details="true"
                :disabled="!available"
                :error="v$.fieldModel.$invalid"
                @focusout="v$.fieldModel.$validate"
            />
        </td>
    </tr>
</template>

<style scoped></style>
