<script lang="ts" setup>
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import { onMounted, onUnmounted, ref } from "vue";
    import { useRouter } from "vue-router";
    import { useDisplay } from "vuetify";
    import { StreamBarcodeReader } from "vue-barcode-reader";

    const onLoaded = () => {
        loading.value = false;
    };

    let keyboardInput = "";

    const router = useRouter();

    function goToOrderWithId(orderNumber: string) {
        console.log(orderNumber);
        const id: number = parseInt(orderNumber.slice(0, 4));
        router.push(`/orders/${id}`);
    }

    const keydownCallback = (event: KeyboardEvent) => {
        console.log(event);
        if (event.key === "Enter") {
            goToOrderWithId(keyboardInput);
        } else {
            keyboardInput += event.key;
        }
    };

    onMounted(() => {
        // Start listener
        document.addEventListener("keydown", keydownCallback, false);
    });

    onUnmounted(() => {
        // Stop listener
        document.removeEventListener("keydown", keydownCallback);
    });

    const { smAndDown } = useDisplay();

    const loading = ref(true);
</script>

<template>
    <v-container fluid>
        <globy-app-bar title="Barcode scanner" />
        <v-row>
            <v-spacer v-show="!smAndDown" />
            <v-col>
                <v-card>
                    <v-card-text>
                        <p>
                            Scan de barcode op een bestelling met de
                            onderstaande scanner.
                        </p>
                        <p>
                            Je kan ook gebruik maken van een fysiek aangesloten
                            scanner.
                        </p>
                        <br />
                        <!--            <p><b>Niet de juiste camera?</b> Selecteer een andere camera onderaan.</p>-->
                        <div align="center">
                            <StreamBarcodeReader
                                @decode="goToOrderWithId"
                                @loaded="onLoaded"
                            />
                        </div>
                        <template v-if="loading">
                            <v-alert>
                                <p>Barcode scanner aan het laden...</p>
                                <p>
                                    Lang aan het wachten? Zorg ervoor dat je de
                                    website toestemming geeft om je camera te
                                    gebruiken!
                                </p>
                            </v-alert>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-spacer v-show="!smAndDown" />
        </v-row>
    </v-container>
</template>

<style scoped></style>
