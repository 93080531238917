<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import { OrderPermissions } from "@/models/OrderPermissions";
    import { formatPrice } from "@/composables/formatter";
    import EditMoneyField from "@/components/orderEdit/EditMoneyField.vue";
    import { computed } from "vue";

    const order = defineModel<OrderDetails>({ required: true });

    defineProps<{
        orderPermissions: OrderPermissions;
    }>();

    const orderTotal = computed(() => {
        return order.value.products.reduce(
            (acc, product) => acc + product.price * product.amount,
            order.value.priceFactors.donation +
                order.value.priceFactors.locationFee -
                order.value.priceFactors.discount,
        );
    });
</script>

<template>
    <v-card title="Pakbon aanpassen">
        <v-table>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Prijs per stuk</th>
                    <th>Aantal</th>
                    <th>Subtotaal</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="product in order.products" :key="product.product.id">
                    <td>{{ product.product.label }}</td>
                    <td>{{ formatPrice(product.price) }}</td>
                    <td>{{ product.amount }}</td>
                    <td>{{ formatPrice(product.price * product.amount) }}</td>
                </tr>
                <td colspan="4">
                    <v-divider></v-divider>
                </td>
                <tr>
                    <td>Donatie</td>
                    <td></td>
                    <td></td>
                    <td>
                        <edit-money-field
                            v-model="order.priceFactors.donation"
                            :can-edit="orderPermissions.categories.billing"
                            hide-zero
                        />
                    </td>
                </tr>

                <tr>
                    <td>Bezorg Toeslag</td>
                    <td></td>
                    <td></td>
                    <td>
                        <edit-money-field
                            v-model="order.priceFactors.locationFee"
                            :can-edit="orderPermissions.categories.billing"
                            hide-zero
                        />
                    </td>
                </tr>
                <tr>
                    <td>Korting</td>
                    <td></td>
                    <td></td>
                    <td>
                        <edit-money-field
                            v-model="order.priceFactors.discount"
                            :can-edit="orderPermissions.categories.discount"
                            hide-zero
                        />
                    </td>
                </tr>
                <td colspan="4">
                    <v-divider class="border-opacity-75"></v-divider>
                </td>
                <tr class="font-weight-bold">
                    <td>Totaal</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatPrice(orderTotal) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>
</template>

<style scoped></style>
