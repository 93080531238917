<script setup lang="ts">
    import { OrderDetails } from "@/models/OrderDetails";

    const props = defineProps<{
        fields: OrderDetails["customFields"];
    }>();
</script>

<template>
    <v-card title="Extra gegevens">
        <v-card-text>
            <v-table>
                <tbody>
                    <tr v-for="field in props.fields" :key="field.field.id">
                        <td>{{ field.field.label }}</td>
                        <td v-if="field.field.datatype == 'boolean'">
                            {{ field.value ? "Ja" : "Nee" }}
                        </td>
                        <td v-else>
                            {{ field.value }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
