<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { computed } from "vue";
    import useVuelidate, { ValidationArgs } from "@vuelidate/core";
    import {
        email,
        required,
        requiredIf,
        telephone,
    } from "@/composables/dutchVuelidate";
    import { OrderDTO, State } from "@/store/orderCreation/types";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();
    const rules = computed(() => {
        const options = orderStore.options;

        const rules: ValidationArgs<Partial<State["order"]>> = {
            // Add rules for the default fields
            name: { required },
            // Add rules for all the toggleablefields
            toggleableFields: {
                email: {
                    requiredIf: requiredIf(
                        options.toggleableFields.email.required,
                    ),
                    email,
                },
                telephone: {
                    requiredIf: requiredIf(
                        options.toggleableFields.telephone.required,
                    ),
                    telephone,
                },
                customer_remark: {
                    requiredIf: requiredIf(
                        options.toggleableFields.customer_remark.required,
                    ),
                },
            },
        };

        return rules as ValidationArgs<OrderDTO>;
    });

    const v$ = useVuelidate(rules, orderStore.order);
</script>

<template>
    <!-- Name -->
    <div class="text-subtitle-1 text-medium-emphasis">Naam*</div>
    <v-text-field
        v-model="orderStore.order.name"
        density="compact"
        placeholder="Naam"
        variant="outlined"
        data-cy="name"
        :error-messages="getVuelidateErrors(v$.name.$errors)"
        @focusout="v$.name.$validate"
    />

    <!-- Telephone -->
    <template v-if="orderStore.options.toggleableFields.telephone.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">
            {{
                "Telefoonnummer" +
                (orderStore.options.toggleableFields.telephone.required
                    ? "*"
                    : "")
            }}
        </div>
        <v-text-field
            v-model="orderStore.order.toggleableFields.telephone"
            density="compact"
            placeholder="Telefoonnummer"
            type="tel"
            variant="outlined"
            data-cy="telephone"
            :error-messages="
                getVuelidateErrors(v$.toggleableFields.telephone.$errors)
            "
            @focusout="v$.toggleableFields.telephone.$validate"
        />
    </template>
    <!-- Email address -->
    <!-- If the customer wants to pay "online" ie. Mollie we need to _require_ this email address. -->
    <template v-if="orderStore.options.toggleableFields.email.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">
            {{
                "Emailadres" +
                (orderStore.options.toggleableFields.email.required ? "*" : "")
            }}
        </div>
        <v-text-field
            v-model="orderStore.order.toggleableFields.email"
            density="compact"
            type="email"
            placeholder="Emailadres"
            variant="outlined"
            data-cy="email"
            :error-messages="
                getVuelidateErrors(v$.toggleableFields.email.$errors)
            "
            @focusout="v$.toggleableFields.email.$validate"
        />
    </template>
</template>

<style scoped></style>
