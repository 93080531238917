import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import { ErrorObject, ValidationRuleWithParams } from "@vuelidate/core";
import { unref } from "vue";

export const getErrors = (errors: ErrorObject[]): string[] => {
    return errors.map((error: ErrorObject) => unref(error.$message));
};

export const required = helpers.withMessage(
    "Dit veld is verplicht",
    validators.required,
);
export const email = helpers.withMessage(
    "Dit is geen geldig e-mailadres",
    validators.email,
);
export const telephone = helpers.withMessage(
    "Dit is geen geldig telefoonnummer, voorbeeld: 06 12345678",
    (value: string) => {
        const val = value.replaceAll(" ", "").replaceAll("-", "");
        return val.length === 10;
    },
);

export const zipcode = helpers.withMessage(
    "De ingevulde postcode is ongeldig.",
    (value: string) =>
        !!value.replaceAll(" ", "").match(/[0-9]{4}([a-zA-Z]{2})/g),
);

export const houseNumber = helpers.withMessage(
    "Het ingevulde huisnummer is ongeldig.",
    (value: string) =>
        (value.replaceAll(" ", "").match(/[0-9]{1,5}([a-zA-Z]{0,5})/g) ?? [])
            .length > 0,
);

export const requiredIf = (condition: boolean): ValidationRuleWithParams =>
    helpers.withMessage(
        "Dit veld is verplicht.",
        validators.requiredIf(condition),
    );

export const integer = helpers.withMessage(
    "Je kan geen kommagetal gebruiken.",
    validators.integer,
);
export const decimal = (decimals: number) =>
    helpers.withMessage(
        `Dit veld moet een getal zijn met maximaal ${decimals} getallen achter de komma.`,
        helpers.regex(new RegExp(`^[0-9]+([,.][0-9]{1,${decimals}})?$`)),
    );

export const minValue = (minimum: number) =>
    helpers.withMessage(
        ({ $params }) => `Het getal mag niet lager zijn dan ${$params.min}.`,
        validators.minValue(minimum),
    );
export const maxValue = (maximum: number) =>
    helpers.withMessage(
        ({ $params }) => `Het getal mag niet hoger zijn dan ${$params.max}.`,
        validators.maxValue(maximum),
    );
export const minLength = (minimum: number) =>
    helpers.withMessage(
        ({ $params }) => `De tekst moet minimaal ${$params.min} tekens zijn.`,
        validators.minLength(minimum),
    );
export const maxLength = (maximum: number) =>
    helpers.withMessage(
        ({ $params }) =>
            `De tekst mag niet meer dan ${$params.max} tekens zijn.`,
        validators.maxLength(maximum),
    );
