import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Create our app object
const app = createApp(App)


// 1. Add Vuetify
import vuetify from "./plugins/vuetify";
app.use(vuetify)

// 2. Add Pinia
// It is important that Pinia is loaded before the router, as the router depends on values in the store.
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

// 3. Add our router implementation
import router from "./router";
import { createPinia } from "pinia";
app.use(router)

// 4. Add Sentry
Sentry.init({
    app,
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.APP_ENV,
    integrations: [
        // new Sentry.BrowserTracing(),
        new Sentry.Replay(),
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


// Add our dependencies to our app object


app.mount('#app')
