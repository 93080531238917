import ApiService from "@/services/ApiService";
import { OrderDTO } from "@/store/orderCreation/types";

const apiService = ApiService;

const CheckoutService = {
    getOptions: () => {
        return apiService.get(`/api/v1/order/checkout/data`);
    },
    postOrder: async (order: OrderDTO) => {
        return apiService.post(`/api/v1/order/checkout/data`, order);
    },
};

export default CheckoutService;
