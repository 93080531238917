<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import { computed, defineProps } from "vue";
    import DeliveryEntries from "@/components/orderDetail/DeliveryEntries.vue";
    import PickupEntries from "@/components/orderDetail/PickupEntries.vue";

    const props = defineProps<{
        order: OrderDetails;
    }>();

    const title = computed(() => {
        return props.order.deliver ? "Bezorg informatie" : "Afhaal informatie";
    });

    const getDay = computed(() => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        return props.order.timeslot.day.toLocaleDateString("nl-NL", options);
    });

    const parseTime = (time: string) => {
        return time.slice(0, -3);
    };
</script>

<template>
    <v-card :title="title" class="mb-5">
        <v-card-text>
            <v-table>
                <tbody>
                    <tr>
                        <td>Dag</td>
                        <td>{{ getDay }}</td>
                    </tr>
                    <tr>
                        <td>Tijdslot</td>
                        <td>
                            {{ parseTime(order!.timeslot.start) }}
                            -
                            {{ parseTime(order!.timeslot.end) }}
                        </td>
                    </tr>
                    <tr>
                        <td>Aflevermethode</td>
                        <td v-if="order!.deliver">Bezorging</td>
                        <td v-else>Afhalen</td>
                    </tr>
                    <delivery-entries
                        v-if="order.deliver"
                        :fulfillment="order.fulfillment"
                    />
                    <pickup-entries v-else :fulfillment="order.fulfillment" />
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
