<script setup lang="ts">
    import { getErrors } from "@/composables/dutchVuelidate";
    import useVuelidate from "@vuelidate/core";

    const fieldModel = defineModel<string>();
    const { rules = {}, canEdit } = defineProps<{
        rules?: unknown;
        canEdit: boolean;
    }>();

    const v$ = useVuelidate({ fieldModel: rules }, { fieldModel });
</script>

<template>
    <v-text-field
        v-if="canEdit"
        v-model="fieldModel"
        variant="outlined"
        density="compact"
        hide-details="auto"
        :error-messages="getErrors(v$.fieldModel.$errors)"
        @focusout="v$.$validate"
    />
    <template v-else>
        <span style="font-size: 1rem">
            {{ fieldModel }}
        </span>
    </template>
</template>
