<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import { requiredIf } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();

    const v$ = useVuelidate(
        {
            customer_remark: {
                requiredIf: requiredIf(
                    orderStore.options.toggleableFields.customer_remark
                        .required,
                ),
            },
            system_remark: {
                requiredIf: requiredIf(
                    orderStore.options.toggleableFields.system_remark.required,
                ),
            },
        },
        {
            customer_remark: orderStore.order.toggleableFields.customer_remark,
            system_remark: orderStore.order.toggleableFields.system_remark,
        },
    );
</script>

<template>
    <template
        v-if="orderStore.options.toggleableFields.customer_remark.enabled"
    >
        <div class="text-subtitle-1 text-medium-emphasis">
            Klant opmerking (zichtbaar voor klant)
        </div>
        <v-textarea
            v-model="orderStore.order.toggleableFields.customer_remark"
            density="compact"
            rows="2"
            placeholder="Opmerking"
            variant="outlined"
            auto-grow
            :error-messages="getVuelidateErrors(v$.customer_remark.$errors)"
            @focusout="v$.customer_remark.$validate"
        />
    </template>

    <template v-if="orderStore.options.toggleableFields.system_remark.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">
            Systeemopmerking (wordt nooit getoont aan klant)
        </div>
        <v-textarea
            v-model="orderStore.order.toggleableFields.system_remark"
            density="compact"
            rows="2"
            placeholder="Systeemopmerking"
            variant="outlined"
            auto-grow
            :error-messages="getVuelidateErrors(v$.system_remark.$errors)"
            @focusout="v$.system_remark.$validate"
        />
    </template>
</template>

<style scoped></style>
