<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { computed } from "vue";
    import { PickupData, pickupPoint } from "@/store/orderCreation/types";
    import useVuelidate from "@vuelidate/core";
    import { required } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();

    const selected = computed(() => {
        return orderStore.options.pickupPoints.find(
            (v: pickupPoint) =>
                v.id ===
                (orderStore.order.fulfillment.data as PickupData).pickupPoint,
        ) as pickupPoint | undefined;
    });

    const updatePickupPoint = (value: pickupPoint | null) => {
        if (!value) {
            orderStore.locationValid = false;
            return;
        }

        orderStore.locationValid = true;
        if (orderStore.order.fulfillment.deliver) {
            throw Error("Order object initialization is incorrect.");
        }

        orderStore.order.fulfillment.data.pickupPoint = value.id;
        orderStore.locationFee = value.fee;
        orderStore.updateBinds(value.products);
    };

    const v$ = useVuelidate({ selected: { required } }, { selected });
</script>

<template>
    <div class="text-subtitle-1 text-medium-emphasis">Afhaalpunt</div>
    <v-select
        v-model="selected"
        :items="orderStore.options.pickupPoints"
        item-title="label"
        density="compact"
        hide-details="auto"
        return-object
        placeholder="Afhaalpunt"
        data-cy="pickup-selector"
        variant="outlined"
        :error-messages="getVuelidateErrors(v$.selected.$errors)"
        @update:model-value="updatePickupPoint"
        @focusout="v$.selected.$validate"
    >
        <template #item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.raw.description" />
        </template>
    </v-select>
</template>

<style scoped></style>
