<script setup lang="ts">
    import { OrderDetails } from "@/models/OrderDetails";

    const props = defineProps<{
        method: OrderDetails["paymentMethod"];
        text: "type" | "label";
    }>();
</script>

<template>
    <v-chip v-if="props.method.type == 'online'" prepend-icon="mdi-web">
        {{ text == "type" ? props.method.type : props.method.label }}
    </v-chip>
    <v-chip v-if="props.method.type == 'card'" prepend-icon="mdi-credit-card">
        {{ text == "type" ? props.method.type : props.method.label }}
    </v-chip>
    <v-chip v-if="props.method.type == 'cash'" prepend-icon="mdi-cash">
        {{ text == "type" ? props.method.type : props.method.label }}
    </v-chip>
    <v-chip v-if="props.method.type == 'invoice'" prepend-icon="mdi-text-box">
        {{ text == "type" ? props.method.type : props.method.label }}
    </v-chip>
</template>

<style scoped></style>
