<template>
    <v-container fluid style="height: 100%">
        <globy-app-bar title="Gebruikers" />
        <v-card>
            <v-card-title>
                <span class="d-flex pa-3">
                    <h1>Alle gebruikers</h1>
                </span>
            </v-card-title>
            <v-card-text>
                <span class="d-flex flex-column">
                    <v-data-table-server
                        v-model:items-per-page="pageLimit"
                        v-model:page="page"
                        :loading="loading"
                        :loading-text="'Gebruikers inladen ...'"
                        :headers="headers"
                        :item-props="true"
                        :items="users"
                        :items-length="totalUsers"
                        :items-per-page-options="itemsPerPageOptions"
                        hover
                    >
                        <template #item="{ item }: { item: User }">
                            <!--                        <tr class="row-pointer"-->
                            <!--                            @click="goToUser(item.props)"-->
                            <!--                        >-->
                            <tr class="row-pointer">
                                <td>
                                    <v-chip
                                        v-if="item.active"
                                        :text="smAndDown ? '' : 'Actief'"
                                        color="success"
                                        prepend-icon="mdi-check-circle"
                                    ></v-chip>
                                    <v-chip
                                        v-else
                                        :text="smAndDown ? '' : 'Inactief'"
                                        color="error"
                                        prepend-icon="mdi-close-circle"
                                    ></v-chip>
                                </td>
                                <td>{{ getFullName(item) }}</td>
                                <td v-if="!smAndDown">{{ item.email }}</td>
                                <td v-if="!smAndDown">
                                    {{ item.telephone }}
                                </td>
                                <td v-if="!smAndDown">{{ item.roles }}</td>
                                <td>
                                    <v-icon
                                        color="grey"
                                        icon="mdi-account-box"
                                    />
                                </td>
                            </tr>
                        </template>
                    </v-data-table-server>
                </span>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script lang="ts" setup>
    import { computed, onMounted, ref, unref, watch } from "vue";
    import { useDisplay } from "vuetify";
    import apiService from "@/services/ApiService";
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import { VDataTableServer } from "vuetify/components/VDataTable";

    // Destructure only the required keys
    const { smAndDown } = useDisplay();
    // const router = useRouter();

    const users = ref([]);
    const loading = ref(true);

    const page = ref(1);
    const pageLimit = ref(5);

    const totalUsers = ref(0);

    // User type
    interface User {
        id: number;
        firstName: string;
        infix: string;
        lastName: string;
        email: string;
        telephone: string;
        active: boolean;
        roles: string[];
    }

    // Load users from the api
    onMounted(() => {
        loadUsers();
    });

    // Watch the page number and items on page variables
    watch([page, pageLimit], () => {
        loadUsers();
    });

    const loadUsers = async () => {
        // Determine the query parameters
        loading.value = true;
        const params = new URLSearchParams();
        params.append("limit", String(unref(pageLimit)));
        params.append("page", String(unref(page)));

        apiService
            .get("/api/v1/admin/user", {
                params,
            })
            .then((response) => {
                users.value = response.data.users;
                totalUsers.value = response.data.total;
                loading.value = false;
            });
    };

    const headers = computed<VDataTableServer["headers"]>(() => {
        let headers = [
            {
                title: "Actief",
                align: "start",
                key: "active",
                sortable: false,
            },
            { title: "Naam", key: "firstName", sortable: false },
        ];

        if (!smAndDown.value) {
            headers = headers.concat([
                { title: "Email adres", key: "email", sortable: false },
                { title: "Telefoonnummer", key: "telephone", sortable: false },
                { title: "Rollen", key: "roles", sortable: false },
            ]);
        }

        headers.push({ title: "", key: "", sortable: false }); // Filler for action icon

        return headers as VDataTableServer["headers"];
    });

    const itemsPerPageOptions = [
        {
            title: "5",
            value: 5,
        },
        {
            title: "10",
            value: 10,
        },
    ];

    // Util function to construct the full name of a user
    const getFullName = (user: User) => {
        return user.firstName + " " + user.infix + " " + user.lastName;
    };

    // Util function to route the user to the page of the clicke user
    // const goToUser = (user) => {
    //     router.push("/admin/users/" + user.id);
    // };
</script>

<style scoped>
    /*
    .row-pointer:hover {
        cursor: pointer;
    }
 */
</style>
