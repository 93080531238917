<script lang="ts" setup>
    import { computed, reactive, ref, unref, watch } from "vue";
    import DymoPrinterService, {
        IDymoPrinter,
    } from "@/services/PrinterServices/DymoPrinterService";
    import { OrderDetails } from "@/models/OrderDetails";
    import orderService from "@/services/OrderService";

    const props = defineProps<{
        modelValue: boolean;
        order: OrderDetails;
    }>();

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "update:modelValue", value: boolean): void;
    }>();

    const modelValue = computed({
        get(): boolean {
            return props.modelValue;
        },
        set(newVal): void {
            emit("update:modelValue", newVal);
        },
    });

    watch(modelValue, (newVal) => {
        if (newVal) {
            // Resetting logic
            hasPrinted.value = false;
            dymo.renderLabel(props.order);
            dymo.scanPrinters();
        }
    });

    const dymo = reactive(new DymoPrinterService());

    const labelPreview = computed(
        () => "data:image/png;base64," + dymo.labelPreview,
    );

    const availablePrinters = computed(() => {
        return dymo.getPrinters();
    });

    const scanPrinters = () => {
        noPrinterSelectedError.value = false;
        dymo.scanPrinters();
    };

    const selectedPrinter = ref<IDymoPrinter>();
    const noPrinterSelectedError = ref<boolean>(false);
    const hasPrinted = ref<boolean>(false);

    const printLabel = () => {
        const printer = unref(selectedPrinter);
        if (!printer) {
            // No printer is selected
            noPrinterSelectedError.value = true;
            return;
        }
        hasPrinted.value = true;
        dymo.print(printer, props.order);
        if (!props.order.state.printed) {
            orderService.alterOrderState(props.order.id, {
                printed: true,
            });
        }
    };

    const printDisabled = computed<boolean>(() => {
        return !unref(selectedPrinter) || unref(hasPrinted);
    });
</script>

<template>
    <v-dialog v-model="modelValue" max-width="500px" persistent width="auto">
        <v-card class="rounded-lg">
            <v-card-title class="bg-info"> Bestelsticker printen</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="purple"
                                prepend-icon="mdi-printer"
                                @click="scanPrinters"
                                >Printers scannen
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="text-subtitle-1 text-medium-emphasis">
                                Beschikbare printers
                            </div>
                            <v-select
                                v-model="selectedPrinter"
                                :items="availablePrinters"
                                density="compact"
                                item-title="name"
                                return-object
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <img :src="labelPreview" />
                        </v-col>
                    </v-row>
                    <v-row v-if="noPrinterSelectedError">
                        <v-col>
                            <v-alert type="error"
                                >Geen printer geselecteerd!
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="info" @click="modelValue = false">
                                Annuleren
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                :disabled="printDisabled"
                                color="info"
                                @click="printLabel"
                                >Printen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
