<script setup lang="ts">
    import markdownit from "markdown-it";
    import { computed } from "vue";

    const md = markdownit();

    const { source } = defineProps<{ source: string }>();

    const htmlResult = computed(() => md.render(source));
</script>

<template>
    <!--    eslint-disable vue/no-v-html -->
    <!--    Markdown It is safe by default, so it is safe to inject the HTML -->
    <div v-html="htmlResult"></div>
    <!--    eslint-enable -->
</template>

<style scoped></style>
