import { defineStore } from "pinia";

export type InstanceState = {
    apiUrl?: string;
    name?: string;
};

export const useInstanceStore = defineStore("instance", {
    state: (): InstanceState => ({
        apiUrl: undefined,
        name: undefined,
    }),
    persist: true,
});
