<template>
    <v-app>
        <GlobyNotificationSnackbar />
        <v-navigation-drawer
            id="sidenavigation"
            v-model="layoutStore.navigationDrawer"
            theme="dark"
        >
            <v-list dense nav>
                <v-list-item>
                    {{ instanceStore.name }}
                </v-list-item>
                <v-list-item>
                    <template #prepend>
                        <v-icon size="large"> mdi-account</v-icon>
                    </template>
                    <v-list-item-title
                        >{{ userStore.firstName + " " + userStore.lastName }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider class="mb-2" />

                <v-list :lines="false" density="default" nav>
                    <nav-link
                        v-for="(item, i) in navigationLinks"
                        :key="i"
                        :icon="item.icon"
                        :to="item.to"
                        :text="item.text"
                    />
                </v-list>
            </v-list>

            <template #append>
                <v-list>
                    <nav-link
                        icon="mdi-exit-to-app"
                        text="Uitloggen"
                        to="/logout"
                    />
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-main style="background-color: #ddd">
            <!--      Here we should put the view component that we want to load for this page -->
            <router-view id="mainwrapper" />
        </v-main>
        <v-footer
            absolute
            app
            :style="{ backgroundColor: development ? '#f00' : '#ccc' }"
            ><span id="footerwrapper">
                Globy App - Copyright &#169;
                {{ new Date().getFullYear() }}
                {{ development ? "LET OP: Dit is een ontwikkelomgeving" : "" }}
            </span>
        </v-footer>
    </v-app>
</template>

<script setup lang="ts">
    import NavLink from "../components/common/NavLink.vue";
    import { computed } from "vue";
    import { useAuthStore } from "@/store/auth";
    import GlobyNotificationSnackbar from "@/components/common/GlobyNotificationSnackbar.vue";
    import { useLayoutStore } from "@/store/layout";
    import { useInstanceStore } from "@/store/instance";

    const development = import.meta.env.DEV;

    const userStore = useAuthStore();
    const instanceStore = useInstanceStore();

    const layoutStore = useLayoutStore();

    const navigationLinks = computed(() => {
        const links = [
            // {
            //     icon: "mdi-view-dashboard",
            //     text: "Dashboard",
            //     to: "/dashboard",
            // },
            // {
            //     icon: "mdi-account",
            //     text: "Profiel",
            //     to: "/profile",
            // },
            {
                icon: "mdi-truck-delivery",
                text: "Bestellingen",
                to: "/orders/overview",
            },
            {
                icon: "mdi-cube-scan",
                text: "Scanner",
                to: "/orders/scan",
            },
            // {
            //     icon: "mdi-plus-box",
            //     text: "Bestelling maken",
            //     to: "/orders/create",
            // },
            // {
            //     icon: "mdi-cog",
            //     text: "Instellingen",
            //     to: "/orders/settings",
            // },
        ];
        // TODO: Add some reason for not allowing placing orders as backoffice user
        links.push({
            icon: "mdi-cart",
            text: "Plaats bestelling",
            to: "/orders/create",
        });

        // If we are admin, add the admin only nav links
        if (userStore.hasRole("ROLE_ADMIN")) {
            links.push({
                icon: "mdi-account-supervisor",
                text: "Gebruikers",
                to: "/admin/users",
            });
        }

        return links;
    });
</script>

<style scoped>
    /* These paddings are necesary to properly handle notches and the statusbar on Iphones */
    #sidenavigation {
        padding-top: env(safe-area-inset-top);
        padding-left: env(safe-area-inset-left);
        padding-bottom: env(safe-area-inset-bottom);
    }

    #footerwrapper {
        padding-bottom: env(safe-area-inset-bottom);
    }

    #mainwrapper {
        padding-top: calc(16px + env(safe-area-inset-top));
        padding-left: max(16px, env(safe-area-inset-left));
        padding-right: max(16px, env(safe-area-inset-right));
    }
</style>
