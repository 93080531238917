import { defineStore } from "pinia";
import { Timeslot } from "@/services/TimeslotService";

export const useOverviewFilterStore = defineStore("orderOverview", {
    state: (): State =>
        <State>{
            filter: {
                paymentStatus: [],
                printStatus: "all",
                deliveryStatus: "all",
                paymentMethod: [],
                deleted: "false",
                timeslot: {
                    day: undefined,
                    slots: [],
                },
                search: "",
                customQuery: "",
            },
        },
    actions: {
        resetFilter() {
            this.filter.paymentStatus = [];
            this.filter.printStatus = "all";
            this.filter.deliveryStatus = "all";
            this.filter.deleted = "false";
            this.filter.paymentMethod = [];
            this.filter.timeslot.day = undefined;
            this.filter.timeslot.slots = [];
            this.filter.search = "";
            this.filter.customQuery = "";
        },
    },
    getters: {
        getODataFilter(): string {
            let filter = "";
            if (this.filter.paymentStatus.length > 0) {
                filter += `paymentStatus in (${this.filter.paymentStatus.join(
                    ",",
                )})`;
            }
            if (this.filter.paymentMethod.length > 0) {
                if (filter) {
                    filter += " and ";
                }
                filter += `paymentMethod.id in (${this.filter.paymentMethod.join(
                    ",",
                )})`;
            }
            if (this.getDaySlotsIds.length > 0) {
                if (filter) {
                    filter += " and ";
                }
                filter += `timeslot.id in (${this.getDaySlotsIds.join(",")})`;
            }

            if (this.filter.printStatus !== "all") {
                if (filter) {
                    filter += " and ";
                }
                filter += `state.printed eq ${this.filter.printStatus}`;
            }
            if (this.filter.deliveryStatus !== "all") {
                if (filter) {
                    filter += " and ";
                }
                filter += `state.delivered eq ${this.filter.deliveryStatus}`;
            }

            if (this.filter.customQuery !== "") {
                if (filter) {
                    filter += " and ";
                }
                filter += this.filter.customQuery;
            }

            // Always add a filter for the deleted property
            if (filter) {
                filter += " and ";
            }
            filter += `state.deleted eq ${this.filter.deleted}`;

            return filter;
        },
        getFilterEnabled(): boolean {
            return (
                this.filter.paymentStatus.length > 0 ||
                this.filter.timeslot.day != undefined ||
                this.filter.printStatus !== "all" ||
                this.filter.deliveryStatus !== "all" ||
                this.filter.paymentMethod.length > 0 ||
                this.filter.customQuery !== ""
            );
        },
        getDaySlots(): { label: string; id: number }[] {
            if (!this.filter.timeslot.day) {
                return [];
            } else {
                return this.filter.timeslot.day.slots.map((slot) => {
                    return {
                        label:
                            slot.start.slice(0, -3) +
                            "-" +
                            slot.end.slice(0, -3),
                        id: slot.id,
                    };
                });
            }
        },
        getDaySlotsIds(): number[] {
            if (!this.filter.timeslot.day) {
                return [];
            }
            if (this.filter.timeslot.slots.length > 0) {
                return this.filter.timeslot.slots;
            } else {
                return this.filter.timeslot.day.slots.map((slot) => {
                    return slot.id;
                });
            }
        },
    },
    persist: true,
});

interface State {
    filter: filter;
}

interface filter {
    paymentStatus: string[];
    paymentMethod: string[];
    printStatus: "true" | "false" | "all";
    deliveryStatus: "true" | "false" | "all";
    deleted: "true" | "false";
    timeslot: {
        day?: Timeslot;
        slots: number[];
    };
    search: string;
    customQuery: string;
}
