<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate, { ValidationArgs } from "@vuelidate/core";
    import { OrderDTO } from "@/store/orderCreation/types";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { computed } from "vue";
    import { helpers, sameAs } from "@vuelidate/validators";

    const orderStore = useOrderCreationStore();

    const rules = computed(() => {
        return {
            tos: {
                assureChecked: helpers.withMessage(
                    "Dit veld is verplicht.",
                    sameAs(true),
                ),
            },
            privacy: {
                assureChecked: helpers.withMessage(
                    "Dit veld is verplicht.",
                    sameAs(true),
                ),
            },
        } as ValidationArgs<Partial<OrderDTO>> as ValidationArgs<OrderDTO>;
    });

    const v$ = useVuelidate(rules, orderStore.order);
</script>

<template>
    <v-checkbox
        v-model="orderStore.order.tos"
        density="compact"
        hide-details="auto"
        data-cy="tos-checkbox"
        label="De klant gaat akkoord met de algemene voorwaarden. *"
        :error-messages="getVuelidateErrors(v$.tos.$errors)"
        @focusout="v$.tos.$validate"
    />
    <v-checkbox
        v-model="orderStore.order.privacy"
        density="compact"
        hide-details="auto"
        data-cy="privacy-checkbox"
        label="De klant gaat akkoord met het privacy beleid. *"
        :error-messages="getVuelidateErrors(v$.privacy.$errors)"
        @focusout="v$.privacy.$validate"
    />
    <p class="mb-3 text-medium-emphasis">
        De algemene voorwaarden en het privacy beleid zijn beschikbaar op de
        bestelwebsite.
    </p>
</template>

<style scoped></style>
