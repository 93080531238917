<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import { decimal, minValue } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { onMounted, ref } from "vue";

    const orderStore = useOrderCreationStore();

    const fieldModel = ref<number | string | undefined>();

    onMounted(() => {
        fieldModel.value = orderStore.order.toggleableFields.donation;
    });

    const updateValueInOrder = () => {
        const val = fieldModel.value?.toString() ?? "";
        fieldModel.value = isNaN(parseFloat(val))
            ? fieldModel.value
            : parseFloat(val);

        orderStore.order.toggleableFields.donation = val;
    };

    const v$ = useVuelidate(
        {
            fieldModel: {
                mustBePositive: minValue(0),
                decimal: decimal(2),
            },
        },
        {
            fieldModel,
        },
    );
</script>

<template>
    <template v-if="orderStore.options.toggleableFields.donation.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">Donatie</div>
        <v-text-field
            v-model="fieldModel"
            density="compact"
            placeholder="Donatie"
            variant="outlined"
            prepend-inner-icon="mdi-currency-eur"
            :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
            @focusout="
                () => {
                    updateValueInOrder();
                    v$.fieldModel.$validate();
                }
            "
        />
    </template>
</template>

<style scoped></style>
