<script lang="ts" setup>
    import { onMounted, ref } from "vue";
    import { useOverviewFilterStore } from "@/store/orderOverview";
    import PaymentStatusChip from "@/components/common/chips/PaymentStatusChip.vue";
    import paymentMethodService from "@/services/PaymentMethodService";
    import PaymentMethodChip from "@/components/common/chips/PaymentMethodChip.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import timeslotService, { Timeslot } from "@/services/TimeslotService";
    import { AxiosResponse } from "axios";
    import { useAuthStore } from "@/store/auth";

    const authStore = useAuthStore();
    const filterStore = useOverviewFilterStore();
    const paymentStatuses = [
        {
            title: "Open",
            value: "open",
        },
        {
            title: "Betaald",
            value: "paid",
        },
        {
            title: "Mislukt",
            value: "failed",
        },
        {
            title: "In afwachting",
            value: "pending",
        },
        {
            title: "Verlopen",
            value: "expired",
        },
        {
            title: "Terug betaald",
            value: "refunded",
        },
    ];

    const paymentMethods = ref<OrderDetails["paymentMethod"][]>([]);

    const timeslots = ref<Timeslot[]>([]);

    onMounted(async () => {
        paymentMethodService
            .getAll()
            .then(
                (response: AxiosResponse<OrderDetails["paymentMethod"][]>) => {
                    paymentMethods.value = response.data;
                },
            );

        timeslots.value = await timeslotService.getAll();
    });

    const resetSlots = () => {
        filterStore.filter.timeslot.slots = [];
    };

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "onPrint"): void;
    }>();

    const expand = ref<boolean>(false);
</script>

<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="w-100 justify-start"
                color="primary"
                prepend-icon="mdi-filter-outline"
                size="large"
                text="Filters"
                variant="tonal"
                @click="expand = !expand"
            />
        </v-card-title>

        <v-expand-transition>
            <div v-show="expand">
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn
                                class="justify-start ml-2"
                                color="purple"
                                prepend-icon="mdi-printer"
                                size="large"
                                text="Printen"
                                variant="tonal"
                                @click="emit('onPrint')"
                            />
                            <v-btn
                                class="justify-start ml-2"
                                color="red"
                                prepend-icon="mdi-close-circle"
                                size="large"
                                text="Filters resetten"
                                variant="tonal"
                                @click="filterStore.resetFilter"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="filterStore.filter.paymentStatus"
                                :items="paymentStatuses"
                                label="Betaalstatus"
                                multiple
                                variant="solo-filled"
                            >
                                <template #selection="{ item }">
                                    <payment-status-chip :status="item.value" />
                                </template>
                            </v-select>
                            <v-select
                                v-model="filterStore.filter.paymentMethod"
                                :items="paymentMethods"
                                item-title="label"
                                item-value="id"
                                label="Betaalmethodes"
                                multiple
                                variant="solo-filled"
                            >
                                <template #selection="{ item }">
                                    <payment-method-chip
                                        :method="item.raw"
                                        text="label"
                                    />
                                </template>
                            </v-select>

                            <v-row>
                                <v-col cols="9">
                                    <v-select
                                        v-model="
                                            filterStore.filter.timeslot.day
                                        "
                                        :item-title="
                                            (item: Timeslot) => {
                                                return new Date(
                                                    item.date,
                                                ).toLocaleDateString();
                                            }
                                        "
                                        :items="timeslots"
                                        clearable
                                        label="Dag"
                                        return-object
                                        variant="solo-filled"
                                        @click:clear="resetSlots"
                                        @update:model-value="resetSlots"
                                    />
                                </v-col>
                                <v-col
                                    cols=" 3
                                    "
                                >
                                    <v-select
                                        v-model="
                                            filterStore.filter.timeslot.slots
                                        "
                                        :disabled="
                                            !filterStore.filter.timeslot.day
                                        "
                                        :items="filterStore.getDaySlots"
                                        item-title="label"
                                        item-value="id"
                                        label="Tijdvak"
                                        multiple
                                        no-data-text="Kies eerst een dag"
                                        variant="solo-filled"
                                    >
                                        <template #selection="{ index }">
                                            <template
                                                v-if="
                                                    filterStore.filter.timeslot
                                                        .slots.length === 1
                                                "
                                            >
                                                1 tijdvak
                                            </template>
                                            <template v-if="index == 1">
                                                {{
                                                    filterStore.filter.timeslot
                                                        .slots.length
                                                }}
                                                tijdvakken
                                            </template>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <p class="text-subtitle-1 text-medium-emphasis">
                                Printstatus
                            </p>
                            <v-radio-group
                                v-model="filterStore.filter.printStatus"
                                inline
                            >
                                <v-radio label="Alle" value="all" />
                                <v-radio label="Niet geprint" value="false" />
                                <v-radio label="Geprint" value="true" />
                            </v-radio-group>
                            <p class="text-subtitle-1 text-medium-emphasis">
                                Bezorgstatus
                            </p>
                            <v-radio-group
                                v-model="filterStore.filter.deliveryStatus"
                                inline
                            >
                                <v-radio label="Alle" value="all" />
                                <v-radio
                                    label="Niet afgeleverd"
                                    value="false"
                                />
                                <v-radio label="Afgeleverd" value="true" />
                            </v-radio-group>
                            <p class="text-subtitle-1 text-medium-emphasis">
                                Verwijderde bestellingen
                            </p>
                            <v-radio-group
                                v-model="filterStore.filter.deleted"
                                inline
                            >
                                <v-radio label="Verwijderd" value="true" />
                                <v-radio
                                    label="Niet verwijderd"
                                    value="false"
                                />
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="authStore.hasRole('ROLE_ADMIN')">
                        <v-col>
                            <v-text-field
                                v-model="filterStore.filter.customQuery"
                                label="Geadvanceerde filter"
                                variant="solo-filled"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<style scoped></style>
