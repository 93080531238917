import ApiService from "@/services/ApiService";

const apiService = ApiService;

export interface Timeslot {
    date: Date;
    slots: {
        id: number;
        start: string;
        end: string;
        alert: unknown; // TODO: Add alert type when alert is needed
    }[];
}

const timeslotService = {
    getAll: async () => {
        return apiService.get("/api/v1/timeslots").then((response) => {
            const timeslots = response.data;
            for (const timeslot of timeslots) {
                timeslot.date = new Date(timeslot.date);
            }
            return timeslots as Timeslot[];
        });
    },
};

export default timeslotService;
