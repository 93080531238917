<script setup lang="ts">
    import ProductTable from "@/components/orderPlacement/product/ProductTable.vue";
    import serviceSelector from "@/components/orderPlacement/service/serviceSelector.vue";
    import { useOrderCreationStore } from "@/store/orderCreation";

    const orderStore = useOrderCreationStore();
</script>

<template>
    <template v-if="orderStore.options.productOptions.mode == 'PRODUCT'">
        <product-table />
    </template>
    <template v-else-if="orderStore.options.productOptions.mode == 'SERVICE'">
        <service-selector />
    </template>
</template>

<style scoped></style>
