<script setup lang="ts">
    import { OrderDetails } from "@/models/OrderDetails";

    const props = defineProps<{
        status: OrderDetails["paymentStatus"];
    }>();
</script>

<template>
    <v-chip
        v-if="props.status == 'open'"
        color="primary"
        prepend-icon="mdi-clock-outline"
    >
        Open
    </v-chip>
    <v-chip
        v-if="status == 'failed'"
        color="error"
        prepend-icon="mdi-exclamation-thick"
    >
        Mislukt
    </v-chip>
    <v-chip
        v-if="status == 'pending'"
        color="warning"
        prepend-icon="mdi-timer-sand"
    >
        In afwachting
    </v-chip>
    <v-chip
        v-if="status == 'expired'"
        color="error"
        prepend-icon="mdi-clock-alert-outline"
    >
        Verlopen
    </v-chip>
    <v-chip
        v-if="status == 'paid'"
        color="success"
        prepend-icon="mdi-check-circle"
    >
        Betaald
    </v-chip>
    <v-chip
        v-if="status == 'refunded'"
        color="success"
        prepend-icon="mdi-cash-refund"
    >
        Terug betaald
    </v-chip>
</template>

<style scoped></style>
