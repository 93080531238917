import { defineStore } from "pinia";

export const useAuthStore = defineStore("user", {
    state: (): State =>
        <State>{
            isAuthenticated: false,
            accessToken: undefined,
            refreshToken: undefined,
            email: "",
            firstName: "",
            infix: "",
            lastName: "",
            roles: [],
        },
    getters: {
        fullName(): string {
            return `${this.firstName} ${this.infix} ${this.lastName}`;
        },
    },
    actions: {
        hasRole(role: string): boolean {
            return this.roles.includes(role);
        },
    },
    persist: true,
});

interface State {
    isAuthenticated: boolean;
    accessToken?: {
        token: string;
        expiry: number;
    };
    refreshToken?: {
        token: string;
        expiry: number;
    };
    email: string;
    firstName: string;
    infix: string;
    lastName: string;
    roles: string[];
}
