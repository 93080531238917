<script lang="ts" setup>
    import { computed, unref } from "vue";
    import { getSumupUrl } from "@/services/PinServices/SumupService";
    import { OrderDetails } from "@/models/OrderDetails";

    const props = defineProps<{
        modelValue: boolean;
        order: OrderDetails;
    }>();

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "update:modelValue", value: boolean): void;
    }>();

    const modelValue = computed({
        get(): boolean {
            return props.modelValue;
        },
        set(newVal): void {
            emit("update:modelValue", newVal);
        },
    });

    const sumupURL = computed(() =>
        getSumupUrl(
            unref(props.order.priceFactors.orderTotal / 100),
            unref(props.order.orderId),
            unref(props.order.id),
        ),
    );
</script>

<template>
    <v-dialog v-model="modelValue" max-width="500px" persistent>
        <v-card class="rounded-lg">
            <v-card-title class="bg-info">
                <span class="headline">Bestelling pinnen</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <p>
                                Deze bestelling moet nog gepind worden. Klik op
                                de knop hieronder om de Sumup app te openen. Let
                                er goed op dat de betaling lukt!
                                <br />
                                <br />
                                Lukt de betaling niet? Probeer het nog eens.
                                Lukt het dan nog niet? Neem dan contact op met
                                de beheersite noodlijn.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="modelValue = false">Annuleren</v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col>
                            <v-btn
                                :href="sumupURL"
                                color="primary"
                                @click="modelValue = false"
                                >Sumup openen</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
