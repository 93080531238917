<script setup lang="ts">
    import { VAlert } from "vuetify/components";
    import { useDisplay } from "vuetify";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { ref } from "vue";
    import AddressLookupService from "@/services/Checkout/AddressLookupService";
    import axios from "axios";
    import useVuelidate from "@vuelidate/core";
    import {
        zipcode as zipcodeValidator,
        houseNumber as houseNumberValidator,
    } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const { smAndUp } = useDisplay();

    const orderStore = useOrderCreationStore();

    const zipcode = ref();
    const houseNumber = ref();

    const street = ref();

    const loading = ref(false);

    const errorAlert = ref<
        | {
              type: VAlert["type"];
              message: string;
          }
        | undefined
    >();

    let timer: number | undefined;

    const debounceUpdateLocationInformation = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(updateLocationInformation, 500) as unknown as number;
    };

    const updateLocationInformation = async () => {
        street.value = "";
        errorAlert.value = undefined;
        orderStore.locationFee = 0;
        orderStore.locationValid = false;

        const zipcodeRegex: RegExpMatchArray | null = (zipcode.value ?? "")
            .replaceAll(" ", "")
            .match(/[0-9]{4}([a-zA-Z]{2})/g);
        const houseNumberRegex = (houseNumber.value ?? "")
            .replaceAll(" ", "")
            .match(/[0-9]{1,5}([a-zA-Z]{0,5})/g);

        if (zipcodeRegex && houseNumberRegex) {
            // Check if the postal code is valid
            loading.value = true;
            AddressLookupService.getAddressByZipcode(
                zipcodeRegex[0],
                houseNumberRegex[0],
            )
                .then((response) => {
                    const data = response.data;

                    street.value =
                        data.street +
                        " " +
                        data.houseNumber +
                        (data.houseNumberSuffix ?? "") +
                        " te " +
                        data.city;

                    orderStore.order.fulfillment.data = {
                        houseNumber: data.houseNumber,
                        houseNumberSuffix: data.houseNumberSuffix,
                        postalCode: zipcodeRegex[0],
                    };

                    if (data.areaId == undefined) {
                        errorAlert.value = {
                            type: "error",
                            message:
                                "De postcode van de klant ligt niet in het bezorggebied.",
                        };
                        return;
                    }

                    orderStore.locationValid = true;

                    orderStore.updateBinds(data.products);
                    orderStore.locationFee = data.locationFee;
                })
                .catch((error) => {
                    if (
                        axios.isAxiosError(error) &&
                        error.response == undefined
                    ) {
                        return;
                    }

                    orderStore.locationValid = false;

                    const data = error.response.data;
                    if (data.error_code === -908) {
                        street.value = "Adres niet gevonden.";
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        } else return "";
    };

    const v$ = useVuelidate(
        {
            zipcode: { zipcodeValidator },
            houseNumber: { houseNumberValidator },
        },
        { zipcode, houseNumber },
    );
</script>

<template>
    <v-row>
        <v-col cols="8" lg="3">
            <div class="text-subtitle-1 text-medium-emphasis">Postcode</div>
            <v-text-field
                v-model="zipcode"
                density="compact"
                placeholder="Postcode"
                variant="outlined"
                hide-details="auto"
                data-cy="postalCode"
                @focusout="
                    () => {
                        debounceUpdateLocationInformation();
                        v$.zipcode.$validate();
                    }
                "
            />
        </v-col>
        <v-col cols="4" lg="3">
            <div class="text-subtitle-1 text-medium-emphasis">
                {{ smAndUp ? "Huisnummer" : "Huis nr." }}
            </div>
            <v-text-field
                v-model="houseNumber"
                density="compact"
                :placeholder="smAndUp ? 'Huisnummer' : 'Huis nr.'"
                hide-details="auto"
                variant="outlined"
                data-cy="houseNumber"
                @focusout="
                    () => {
                        debounceUpdateLocationInformation();
                        v$.houseNumber.$validate();
                    }
                "
            />
        </v-col>
        <v-col lg="6">
            <div class="text-subtitle-1 text-medium-emphasis">Straatnaam</div>
            <v-text-field
                v-model="street"
                :loading="loading"
                density="compact"
                placeholder="Automatisch aangevuld"
                readonly
                data-cy="street"
                :hide-details="true"
                variant="outlined"
            />
        </v-col>
    </v-row>
    <v-row v-show="v$.$errors.length > 0">
        <v-col>
            <v-alert
                color="error"
                density="compact"
                :text="getVuelidateErrors(v$.$errors)[0] ?? undefined"
            />
        </v-col>
    </v-row>
    <v-row v-if="errorAlert">
        <v-col>
            <v-alert :type="errorAlert.type">
                {{ errorAlert.message }}
            </v-alert>
        </v-col>
    </v-row>
</template>

<style scoped></style>
