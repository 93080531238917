<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { computed } from "vue";
    import StringField from "@/components/orderPlacement/customFields/StringField.vue";
    import BooleanField from "@/components/orderPlacement/customFields/BooleanField.vue";
    import IntegerField from "@/components/orderPlacement/customFields/IntegerField.vue";

    const props = defineProps<{
        type: "pre" | "post";
    }>();

    const fields = computed(() => {
        return props.type === "pre"
            ? orderStore.options.preProductOptions
            : orderStore.options.postProductOptions;
    });

    const orderStore = useOrderCreationStore();
</script>

<template>
    <span v-for="field in fields" :key="field.id">
        <string-field v-if="field.datatype == 'string'" :field="field" />
        <boolean-field v-if="field.datatype == 'boolean'" :field="field" />
        <integer-field v-if="field.datatype == 'integer'" :field="field" />
    </span>
</template>

<style scoped></style>
