<script setup lang="ts">
    import { computed } from "vue";

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "update:modelValue", value: boolean): void;
        // eslint-disable-next-line no-unused-vars
        (e: "delete"): void;
    }>();

    const modelValue = computed({
        get(): boolean {
            return props.modelValue;
        },
        set(newVal): void {
            emit("update:modelValue", newVal);
        },
    });

    const confirmDeletion = () => {
        emit("delete");
        modelValue.value = false;
    };
</script>

<template>
    <v-dialog v-model="modelValue" max-width="500px" persistent width="auto">
        <v-card class="rounded-lg">
            <v-card-title class="bg-info"> Bestelsticker printen</v-card-title>
            <v-card-text
                >Weet je zeker dat je deze bestelling als verwijderd wilt
                markeren?</v-card-text
            >
            <v-card-actions>
                <v-btn
                    variant="tonal"
                    text="Annuleren"
                    @click="modelValue = false" />
                <v-spacer />
                <v-btn
                    color="red"
                    variant="tonal"
                    text="Bevestigen"
                    @click="confirmDeletion"
            /></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
