<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import { required } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { computed, WritableComputedRef } from "vue";

    const orderStore = useOrderCreationStore();

    const selected: WritableComputedRef<number> = computed({
        get: () => orderStore.order.paymentMethod,
        set: (id: number): void => {
            orderStore.order.paymentMethod = id;
        },
    });

    const v$ = useVuelidate(
        {
            paymentMethod: { required },
        },
        {
            paymentMethod: selected,
        },
    );
</script>

<template>
    <template v-if="orderStore.options.paymentMethods.length > 1">
        <div class="text-subtitle-1 text-medium-emphasis">Betaalmethode</div>
        <v-select
            v-model="selected"
            :items="orderStore.options.paymentMethods"
            item-value="id"
            item-title="label"
            density="compact"
            placeholder="Betaalmethode"
            variant="outlined"
            :error-messages="getVuelidateErrors(v$.paymentMethod.$errors)"
            @focusout="v$.paymentMethod.$validate()"
        />
    </template>
    <template v-else>
        <div class="text-subtitle-1">
            De klant betaalt met
            {{ orderStore.options.paymentMethods[0].label }}
        </div>
        <br />
    </template>
</template>

<style scoped></style>
