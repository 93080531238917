<template>
    <v-overlay
        :model-value="true"
        :no-click-animation="true"
        :persistent="true"
        :scrim="false"
        class="justify-center pb-sm-5 align-end"
        scroll-strategy="none"
    >
        <v-expand-transition>
            <v-alert
                v-show="notificationStore.currentNotification"
                :model-value="true"
                elevation="5"
                :closable="true"
                :type="notificationCache?.type"
                :icon="notificationCache?.icon"
                :min-width="smAndDown ? '' : '400px'"
                @update:model-value="
                    () => {
                        /*Ignore the update event to prevent the alert from disappearing.*/
                    }
                "
                @click:close="notificationStore.popNotification"
            >
                {{ notificationCache?.text }}
            </v-alert>
        </v-expand-transition>
    </v-overlay>
</template>

<script lang="ts" setup>
    import { Notification, useNotificationStore } from "@/store/notification";
    import { useDisplay } from "vuetify";
    import { ref, watch } from "vue";

    const { smAndDown } = useDisplay();

    const notificationStore = useNotificationStore();

    // This cache must exist so when the last notification in a sequence is removed,
    // the old alert can still be displayed during the animation.
    const notificationCache = ref<Notification | undefined>();
    watch(
        () => notificationStore.currentNotification,
        (notification) => {
            if (notification) {
                notificationCache.value = notification;
            }
        },
    );
</script>

<style scoped></style>
