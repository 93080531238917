export const getSumupUrl = (
    amount: number,
    orderNumber: string,
    orderId: number,
) => {
    return (
        "sumupmerchant://pay/1.0" +
        // + "?affiliate-key=" + "7ca84f17-84a5-4140-8df6-6ebeed8540fc"
        "?affiliate-key=" +
        import.meta.env.VITE_SUMUP_AFFILIATE_KEY +
        (import.meta.env.VITE_SUMUP_APP_ID
            ? "&app-id=" + import.meta.env.VITE_SUMUP_APP_ID
            : "") +
        "&total=" +
        amount +
        "&currency=EUR" +
        "&foreign-tx-id=ord-" +
        orderId +
        "-" +
        new Date().getTime() +
        "&title=Bestelling " +
        orderNumber +
        "&skip-screen-success=true" +
        "&callback=" +
        import.meta.env.PUBLIC_URL +
        "/orders/sumupcallback/" +
        orderId
    );
};
