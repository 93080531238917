<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { computed } from "vue";
    import { deliveryDay, deliverySlot } from "@/store/orderCreation/types";

    const noSlotsAvailable = computed(() => {
        return (
            orderStore.selectedProducts.length > 0 &&
            orderStore.getAvailableDays.length == 0
        );
    });

    const convertDay = (day: deliveryDay) => {
        const label = new Date(day.date).toLocaleDateString("nl-NL", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        } as Intl.DateTimeFormatOptions);

        return label.charAt(0).toUpperCase() + label.slice(1);
    };

    const convertTime = (value: deliverySlot | number) => {
        // In the case the timeslot is deleted from the list of available timeslots
        //  return an empty string to prevent the v-select from crashing
        if (typeof value == "number") return "";

        return value.start.slice(0, -3) + " - " + value.end.slice(0, -3);
    };

    const orderStore = useOrderCreationStore();
</script>

<template>
    <template v-if="noSlotsAvailable">
        <v-alert type="error">
            Oei, de klant wil teveel bestellen. We hebben geen tijdvakken meer
            vrij met voldoende voorraad.
        </v-alert>
        <br />
    </template>
    <div class="text-subtitle-1 text-medium-emphasis">
        Kies een bezorgmoment
    </div>
    <v-row class="pb-5">
        <v-col cols="12" md="6">
            <v-select
                v-model="orderStore.selectedDay"
                :items="orderStore.getAvailableDays"
                :item-title="convertDay"
                :disabled="orderStore.getAvailableDays.length == 0"
                data-cy="timeslotDay"
                return-object
                hide-details="auto"
                placeholder="Bezorgmoment"
                variant="outlined"
                density="compact"
            />
        </v-col>

        <v-col cols="12" md="6">
            <v-select
                v-model="orderStore.order.fulfillment.timeslot"
                :disabled="!orderStore.selectedDay"
                :items="orderStore.getAvailableTimeslots"
                item-value="id"
                data-cy="timeslotTime"
                hide-details="auto"
                :item-title="convertTime"
                placeholder="Tijd"
                variant="outlined"
                density="compact"
            />
        </v-col>
    </v-row>
</template>

<style scoped></style>
