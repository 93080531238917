<script setup lang="ts">
    import { required } from "@/composables/dutchVuelidate";
    import { OrderDetails } from "@/models/OrderDetails";
    import EditTextField from "@/components/orderEdit/EditTextField.vue";
    import { OrderPermissions } from "@/models/OrderPermissions";
    import PaymentStatusChip from "../common/chips/PaymentStatusChip.vue";
    import PaymentMethodChip from "../common/chips/PaymentMethodChip.vue";
    import DeliveryStatusChip from "@/components/common/chips/DeliveryStatusChip.vue";

    const order = defineModel<OrderDetails>({ required: true });

    defineProps<{
        orderPermissions: OrderPermissions;
    }>();
</script>

<template>
    <v-card class="mb-5" title="Bestel informatie">
        <v-card-text>
            <v-table theme="light">
                <tbody>
                    <tr>
                        <td>Bestelnummer</td>
                        <td>
                            <EditTextField
                                v-model="order.orderId"
                                :can-edit="false"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Naam klant</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.name"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Opmerking (klant)</td>
                        <td>
                            <EditTextField
                                v-model="order.remarks.customerRemark"
                                :can-edit="orderPermissions.categories.customer"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Telefoonnummer</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.telephone"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Mailadres</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.email"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalmethode</td>
                        <td>
                            <PaymentMethodChip
                                :method="order.paymentMethod"
                                text="label"
                            ></PaymentMethodChip>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalstatus</td>
                        <td>
                            <payment-status-chip
                                :status="order.paymentStatus"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Bezorgstatus</td>
                        <td>
                            <delivery-status-chip
                                :delivered="order.state.delivered"
                                :deliver="order.deliver"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>
