<script setup lang="ts">
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import { onMounted, ref } from "vue";
    import CheckoutCard from "@/components/orderPlacement/CheckoutCard.vue";
    import { useOrderCreationStore } from "@/store/orderCreation";

    const orderStore = useOrderCreationStore();

    const loading = ref(true);

    onMounted(async () => {
        await orderStore.fetchOptions();
        orderStore.resetOrder();
        loading.value = false;
    });

    const reload = () => {
        // Ask user for confirmation
        orderStore.fetchOptions();
        orderStore.resetOrder();
    };
</script>

<template>
    <v-container fluid>
        <globy-app-bar title="Plaats bestelling">
            <template #actions>
                <v-btn icon="mdi-reload" @click="reload" />
            </template>
        </globy-app-bar>
        <template v-if="!loading">
            <v-card>
                <checkout-card />
            </v-card>
        </template>
    </v-container>
</template>

<style scoped></style>
