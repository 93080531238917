<script setup lang="ts">
    import {
        customField,
        customIntegerConfiguration,
    } from "@/store/orderCreation/types";
    import { computed, onMounted, ref } from "vue";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import {
        maxValue,
        minValue,
        requiredIf,
    } from "@/composables/dutchVuelidate";
    import useVuelidate from "@vuelidate/core";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();

    const props = defineProps<{
        field: customField;
    }>();

    const fieldModel = ref<number>(0);

    const rules = computed(() => {
        const config = props.field.configuration as customIntegerConfiguration;
        // any is required because the rule set is dynamic and cannot be predetermined
        // eslint-disable-next-line
        const rules: any = {
            requiredIf: requiredIf(props.field.required),
        };

        if (config.max !== undefined) {
            rules.maxLength = maxValue(config.max);
        }
        if (config.min !== undefined) {
            rules.minLength = minValue(config.min);
        }

        return { fieldModel: rules };
    });
    const v$ = useVuelidate(rules, { fieldModel });

    onMounted(() => {
        fieldModel.value = (orderStore.order.customFields.find(
            (field) => field.id === props.field.id,
        )?.value ?? "") as number;
    });

    const updateValueInOrder = () => {
        const val = fieldModel.value?.toString() ?? "";
        fieldModel.value = isNaN(parseInt(val))
            ? fieldModel.value
            : parseInt(val);

        orderStore.setCustomField({
            id: props.field.id,
            value: fieldModel.value as number,
        });
    };

    const dataCy = computed(() => "custom-" + props.field.id);
</script>

<template>
    <div class="text-subtitle-1 text-medium-emphasis">
        {{ field.label }} {{ field.required ? "*" : "" }}
    </div>
    <v-text-field
        v-model="fieldModel"
        class="pb-5"
        :data-cy="dataCy"
        :placeholder="props.field.placeholder"
        :min="(props.field.configuration as customIntegerConfiguration).min"
        :max="(props.field.configuration as customIntegerConfiguration).max"
        density="compact"
        hide-details="auto"
        variant="outlined"
        :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
        @focusout="
            () => {
                updateValueInOrder();
                v$.fieldModel.$validate();
            }
        "
    />
</template>

<style scoped></style>
