<script setup lang="ts">
    import { customField } from "@/store/orderCreation/types";
    import { computed, onMounted, ref } from "vue";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { helpers, sameAs } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";

    const orderStore = useOrderCreationStore();

    const props = defineProps<{
        field: customField;
    }>();

    const fieldModel = ref<boolean>(false);

    const rules = computed(() => {
        if (!props.field.required) return { fieldModel: {} };
        return {
            fieldModel: {
                assureChecked: helpers.withMessage(
                    "Dit veld is verplicht.",
                    sameAs(true),
                ),
            },
        };
    });
    const v$ = useVuelidate(rules, { fieldModel });

    onMounted(() => {
        fieldModel.value = (orderStore.order.customFields.find(
            (field) => field.id === props.field.id,
        )?.value ?? "") as boolean;
    });

    const updateValueInOrder = () => {
        orderStore.setCustomField({
            id: props.field.id,
            value: fieldModel.value,
        });
    };

    const dataCy = computed(() => "custom-" + props.field.id);
</script>

<template>
    <v-checkbox
        v-model="fieldModel"
        :data-cy="dataCy"
        :label="props.field.label + (props.field.required ? '*' : '')"
        :true-value="true"
        :false-value="false"
        density="compact"
        variant="outlined"
        :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
        @input="
            () => {
                updateValueInOrder();
                v$.fieldModel.$validate();
            }
        "
    />
</template>

<style scoped></style>
