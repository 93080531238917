<template>
    <v-chip
        :color="props.delivered ? 'success' : 'error'"
        :prepend-icon="
            props.delivered ? 'mdi-check-circle' : 'mdi-close-circle'
        "
        :text="text"
    />
</template>
<script setup lang="ts">
    import { computed } from "vue";

    const props = defineProps<{
        delivered: boolean;
        deliver: boolean;
    }>();

    const text = computed(() => {
        if (props.deliver) {
            return props.delivered ? "Bezorgd" : "Niet bezorgd";
        } else {
            return props.delivered ? "Opgehaald" : "Niet opgehaald";
        }
    });
</script>
