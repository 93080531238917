<template>
    <v-app-bar id="navbar" color="primary">
        <v-app-bar-nav-icon @click="layoutStore.toggleNavigationDrawer" />
        <v-btn
            v-if="props.detail"
            icon="mdi-arrow-left"
            @click="router.push({ path: props.detail })"
        />
        <v-app-bar-title>
            <span>
                {{ props.title }}
            </span>
        </v-app-bar-title>
        <span class="mx-5">
            <slot name="actions" />
        </span>
    </v-app-bar>
</template>

<script lang="ts" setup>
    import { useLayoutStore } from "@/store/layout";
    import { useRouter } from "vue-router";

    const props = defineProps<{
        title: string;
        detail?: string | undefined;
    }>();

    const router = useRouter();
    const layoutStore = useLayoutStore();
</script>

<style scoped>
    #navbar {
        padding-top: env(safe-area-inset-top);
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
</style>
