import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
    state: (): State =>
        <State>{
            navigationDrawer: undefined,
        },
    actions: {
        toggleNavigationDrawer(): void {
            this.navigationDrawer = !this.navigationDrawer;
        },
    },
});

interface State {
    navigationDrawer?: boolean;
}
