<script setup lang="ts">
    import {
        customField,
        customStringConfiguration,
    } from "@/store/orderCreation/types";
    import { computed, onMounted, ref } from "vue";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import {
        maxLength,
        minLength,
        requiredIf,
    } from "@/composables/dutchVuelidate";
    import { helpers } from "@vuelidate/validators";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();

    const props = defineProps<{
        field: customField;
    }>();

    const fieldModel = ref<string>("");

    const rules = computed(() => {
        const config = props.field.configuration as customStringConfiguration;
        // any is required because the rule set is dynamic and cannot be predetermined
        // eslint-disable-next-line
        const rules: any = {
            requiredIf: requiredIf(props.field.required),
        };

        if (config.length !== undefined) {
            if (config.length.max !== undefined) {
                rules.maxLength = maxLength(config.length.max);
            }
            if (config.length.min !== undefined) {
                rules.minLength = minLength(config.length.min);
            }
        }

        if (config.regex) {
            rules.regex = helpers.regex(config.regex);
        }

        return { fieldModel: rules };
    });
    const v$ = useVuelidate(rules, { fieldModel });

    onMounted(() => {
        fieldModel.value = (orderStore.order.customFields.find(
            (field) => field.id === props.field.id,
        )?.value ?? "") as string;
    });

    const updateValueInOrder = () => {
        orderStore.setCustomField({
            id: props.field.id,
            value: fieldModel.value,
        });
    };

    const dataCy = computed(() => "custom-" + props.field.id);
</script>

<template>
    <div class="text-subtitle-1 text-medium-emphasis">
        {{ field.label }} {{ field.required ? "*" : "" }}
    </div>
    <v-textarea
        v-model="fieldModel"
        auto-grow
        class="pb-5"
        density="compact"
        hide-details="auto"
        :placeholder="field.placeholder"
        variant="outlined"
        :data-cy="dataCy"
        :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
        @focusout="
            () => {
                updateValueInOrder();
                v$.fieldModel.$validate();
            }
        "
    />
</template>

<style scoped></style>
