<script lang="ts" setup>
    import MarkdownText from "@/components/common/MarkdownText.vue";
    import privacyMarkdown from "@/assets/privacy.md?raw";

    import { useDisplay } from "vuetify";

    const { smAndDown } = useDisplay();
</script>

<template>
    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-card-title>Privacy beleid Globy Backoffice</v-card-title>
        <v-card-text>
            <MarkdownText :source="privacyMarkdown" />
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" variant="flat" @click="$router.back()"
                >Terug</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<style scoped></style>
