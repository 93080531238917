<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import ProductTableEntry from "@/components/orderPlacement/product/ProductTableEntry.vue";

    const orderStore = useOrderCreationStore();
</script>

<template>
    <v-table data-cy="productTable">
        <thead>
            <tr>
                <th>Product</th>
                <th>Prijs</th>
                <th>Hoeveelheid</th>
            </tr>
        </thead>
        <tbody>
            <template
                v-for="item in orderStore.availableProducts"
                :key="item.id"
            >
                <product-table-entry :product="item" :disabled="false" />
            </template>
        </tbody>
    </v-table>
</template>

<style scoped></style>
