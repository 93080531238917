<script setup lang="ts">
    import { reactive, ref } from "vue";
    import { MaskInputOptions } from "maska";
    import useVuelidate from "@vuelidate/core";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { vMaska } from "maska/vue";

    const fieldModel = defineModel<number>();
    const {
        rules = {},
        canEdit,
        hideZero,
    } = defineProps<{
        rules?: unknown;
        canEdit: boolean;
        hideZero?: boolean;
    }>();

    const modelRef = ref(fieldModel.value);
    const v$ = useVuelidate({ fieldModel: rules }, { fieldModel });

    const moneyMask = reactive<MaskInputOptions>({
        onMaska: (detail) => {
            const parsed = parseFloat(detail.unmasked);
            if (!isNaN(parsed)) {
                fieldModel.value = parsed * 100;
            } else {
                fieldModel.value = 0;
            }
        },
        number: {
            locale: "nl",
            fraction: 2,
            unsigned: true,
        },
        postProcess: (val) => `€ ${val}`,
    });
</script>

<template>
    <v-text-field
        v-if="canEdit"
        v-model="modelRef"
        v-maska="moneyMask"
        :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
        density="compact"
        hide-details="auto"
        single-line
        variant="outlined"
        @focusout="v$.fieldModel.$validate"
    />
    <template v-else-if="!(hideZero || (fieldModel ?? 0) < 0)">
        <span style="font-size: 1rem">
            {{ fieldModel }}
        </span>
    </template>
</template>

<style scoped></style>
