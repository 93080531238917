<script setup lang="ts">
    import { VAlert } from "vuetify/components";
    import { useDisplay } from "vuetify";
    import { useRouter } from "vue-router";
    import { ref } from "vue";
    import authenticationService from "@/services/AuthenticationService";
    import axios from "axios";
    import {
        getErrors,
        required,
        email as emailValidator,
    } from "@/composables/dutchVuelidate";
    import useVuelidate from "@vuelidate/core";

    // Load the smAndDown display breakpoint
    const { smAndDown } = useDisplay();
    const router = useRouter();

    // Alert data on top of the page
    const alert = ref<{
        type: VAlert["type"];
        message: string;
    }>({
        type: undefined,
        message: "",
    });

    // The users email
    const email = ref();
    // Is the form loading right now?
    const formLoading = ref<boolean>(false);

    const rules = {
        email: {
            required,
            email: emailValidator,
        },
    };

    const v$ = useVuelidate(rules, {
        email,
    });

    // Submit the form to the backend
    const submit = async () => {
        formLoading.value = true;

        // Reset notification
        alert.value = {
            type: undefined,
            message: "",
        };

        // Validate form
        v$.value.$touch();
        if (v$.value.$invalid) {
            formLoading.value = false;
            return;
        }

        try {
            // Try sending requesting a password reset
            await authenticationService.requestReset(email.value);

            alert.value = {
                type: "success",
                message: `We hebben een mail gestuurt naar ${email.value} om je wachtwoord te veranderen.`,
            };
        } catch (e) {
            // Catch any errors
            if (axios.isAxiosError(e)) {
                alert.value = {
                    type: "error",
                    message: "Er is iets misgegaan. Probeer het later opnieuw.",
                };
            }
        } finally {
            // In all cases, we have finished loading
            formLoading.value = false;
        }
    };
</script>

<template>
    <v-expand-transition>
        <span v-show="alert.message !== ''">
            <v-alert
                theme="light"
                :type="alert.type"
                :max-width="smAndDown ? undefined : 500"
            >
                {{ alert.message }}
            </v-alert>
            <br />
        </span>
    </v-expand-transition>

    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-card-title>Wachtwoord vergeten</v-card-title>
        <v-card-text>
            <v-form @keyup.enter="submit" @submit.prevent="submit">
                <div class="text-subtitle-1 text-medium-emphasis">Email</div>
                <v-text-field
                    v-model="email"
                    density="compact"
                    placeholder="E-mailadres"
                    variant="outlined"
                    type="email"
                    hide-details="auto"
                    :error-messages="getErrors(v$.email.$errors)"
                    data-cy="email"
                    @blur="v$.email.$touch"
                />
                <br />
            </v-form>
            <v-row>
                <v-col>
                    <v-btn
                        color="primary"
                        variant="tonal"
                        @click="router.push('login')"
                        >Inloggen
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col>
                    <v-btn
                        color="primary"
                        variant="flat"
                        :loading="formLoading"
                        @click="submit"
                        >Wachtwoord herstellen
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
