<template>
    <div class="text-subtitle-1 text-medium-emphasis">
        Welk type bezoek wilt de klant?
    </div>
    <v-select
        v-model="selected"
        :items="orderStore.availableServices"
        :item-title="labelProduct"
        item-value="id"
        return-object
        density="compact"
        variant="outlined"
        hide-details="auto"
        data-cy="serviceSelector"
        placeholder="Kies welke type bezoek de klant wilt"
        :error-messages="getVuelidateErrors(v$.selected.$errors)"
        @focusout="v$.selected.$validate"
    />

    <v-alert v-if="selected" class="mt-5">
        {{ selected?.description }}
    </v-alert>
</template>

<script setup lang="ts">
    import { formatPrice } from "@/composables/formatter";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import { displayItem } from "@/store/orderCreation/types";
    import { WritableComputedRef, computed } from "vue";
    import useVuelidate from "@vuelidate/core";
    import { required } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";

    const orderStore = useOrderCreationStore();

    const selected: WritableComputedRef<displayItem | undefined> = computed({
        get: () => {
            const selectedProducts = orderStore.selectedProducts;
            if (selectedProducts.length == 0) {
                return undefined;
            }
            const id = selectedProducts[0].id;

            return orderStore.options.productOptions.products.find(
                (product) => product.id == id,
            );
        },
        set: (_value: displayItem | undefined) => {
            orderStore.selectService(_value?.id);
        },
    });

    const v$ = useVuelidate({ selected: { required } }, { selected });

    const labelProduct = (product: displayItem): string => {
        return `${product.label}${product.price ? " " + formatPrice(product.price) : ""}`;
    };
</script>

<style scoped></style>
