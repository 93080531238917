<script setup lang="ts">
    import { formatPrice } from "@/composables/formatter";
    import { OrderDetails } from "@/models/OrderDetails";

    const props = defineProps<{
        order: OrderDetails;
    }>();
</script>

<template>
    <v-card title="Pakbon">
        <v-table>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Prijs per stuk</th>
                    <th>Aantal</th>
                    <th>Subtotaal</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="product in props.order.products"
                    :key="product.product.id"
                >
                    <td>{{ product.product.label }}</td>
                    <td>{{ formatPrice(product.price) }}</td>
                    <td>{{ product.amount }}</td>
                    <td>{{ formatPrice(product.price * product.amount) }}</td>
                </tr>
                <td colspan="4">
                    <v-divider></v-divider>
                </td>
                <tr v-if="order.priceFactors.donation > 0">
                    <td>Donatie</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatPrice(order.priceFactors.donation) }}</td>
                </tr>
                <tr v-if="order.priceFactors.locationFee > 0">
                    <td>Bezorg Toeslag</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatPrice(order.priceFactors.locationFee) }}</td>
                </tr>
                <tr v-if="order.priceFactors.discount > 0">
                    <td>Korting</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatPrice(-order.priceFactors.discount) }}</td>
                </tr>
                <td colspan="4">
                    <v-divider class="border-opacity-75"></v-divider>
                </td>
                <tr class="font-weight-bold">
                    <td>Totaal</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatPrice(order.priceFactors.orderTotal) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>
</template>

<style scoped></style>
