import ApiService from "@/services/ApiService";

const apiService = ApiService;

const AddressLookupService = {
    getAddressByZipcode: (zipcode: string, houseNumber: string) => {
        const searchParams = new URLSearchParams();
        searchParams.append("postalCode", zipcode);
        searchParams.append("houseNumber", houseNumber);

        return apiService.get(
            `/api/v1/address/lookup?${searchParams.toString()}`,
        );
    },
};

export default AddressLookupService;
